<template>
  <v-container
    id="login"
    style="height: 100%"
    class="d-flex align-center justify-center"
  >
    <v-row>
      <v-col cols="12">
        <h2>Login</h2>
        <v-form
          ref="form"
          @submit.prevent="login()"
          class="size-l text-center"
          v-model="valid"
        >
          <v-text-field
            v-model="username"
            label="Username"
            required
            autocomplete="username"
            clearable
            :rules="[(v) => !!v || 'Campo obbligatorio']"
          ></v-text-field>
          <v-text-field
            v-model="pswd"
            label="Password"
            required
            autocomplete="current-password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            clearable
            :rules="[(v) => !!v || 'Campo obbligatorio']"
          ></v-text-field>
          <div class="text-end">
            <span @click="recuperaPswd()" class="rec-pswd"
              >Recupera password</span
            >
            <!-- TESTO PER LA MANUTENZIONE -->
            <!-- <p
              v-if="currentDate <= 20240518"
              :style="currentDate >= 20240515 ? 'color: red' : 'color: black'"
              class="text-start"
            >
              <b>
                Vi informiamo che in data 18/05 questo portale web non sarà raggiungibile per effetto di un intervento di manutenzione. <br />Ci scusiamo per il disagio.
              </b>
            </p> -->
          </div>

          <v-btn
            color="primary"
            class="mt-2"
            :disabled="!valid"
            type="submit"
            rounded
          >
            Login
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import router from "../router";

export default {
  name: "Login",
  data: () => ({
    username: "",
    pswd: "",
    show: false,
    valid: false,
    currentDate: null,
  }),
  created() {
    var d = new Date();
    this.currentDate = parseInt(
      `${d.getFullYear()}${(d.getMonth() + 1).toString().padStart(2, "0")}${d
        .getDate()
        .toString()
        .padStart(2, "0")}`
    );
  },
  methods: {
    login() {
      this.$store.state.loading = true;
      this.axios
        .post(`${this.$store.state.url_connessione}FoUtentis/Login`, {
          username: this.username,
          password: this.pswd,
        })
        .then((response) => {
          this.$store.state.loading = false;
          // response.data.tipologia = "Accesso Negato"
          if (response.data.passwordLink != "") {
            router.push(`/nuova-password/${response.data.passwordLink}`);
          } else {
            if (
              response.data.tipologia != "Accesso Negato" &&
              response.data.tipologia != "Codice non trovato"
            ) {
              this.axios.defaults.headers.common["authorization"] =
                response.data.jwt;
              localStorage.setItem("jwt", response.data.jwt);
              this.$store.state.tipologia = response.data.tipologia;
              this.$store.state.fornitore = response.data.fornitore;
              localStorage.setItem("tipologia", response.data.tipologia);
              localStorage.setItem("fornitore", response.data.fornitore);
            }

            if (response.data.tipologia == "P") {
              router.push("/prefatture");
            } else if (response.data.tipologia == "T") {
              router.push("/trazionisti");
            } else if (
              response.data.tipologia == "A" ||
              response.data.tipologia == "S"
            ) {
              this.$store.state.fornitoriPrefatture =
                response.data.fornitoriPrefatture;
              localStorage.setItem(
                "fornitoriPrefatture",
                JSON.stringify(response.data.fornitoriPrefatture)
              );
              this.$store.state.fornitoriTrazionisti =
                response.data.fornitoriTrazionisti;
              localStorage.setItem(
                "fornitoriTrazionisti",
                JSON.stringify(response.data.fornitoriTrazionisti)
              );
              // localStorage.setItem("fornitori", JSON.stringify(["123", "543"]));
              router.push("/home");
            } else {
              this.$root.Snackbar.show({
                text: "Accesso negato",
                type: "error",
              });
            }
          }
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
          }
        });
    },
    recuperaPswd() {
      router.push("/recupera-password");
    },
  },
};
</script>


<style>
#login .rec-pswd {
  cursor: pointer;
}

#login .rec-pswd:hover {
  font-weight: bold;
  color: #161c2d;
}
</style>